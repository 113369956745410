
import { Settings } from '@/services/SOLO';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { eventHandler } from '@/mixins'
import EventTimes from '@/models/EventTimes';
import moment from 'moment';
import 'moment/locale/pt-br';
import { TimeEvent } from '@/models';
import { translations } from '@/mixins'

@Component({
  mixins: [eventHandler, translations]
})
export default class TimeEventCards extends Vue {
  
  @Prop() items!: Array<TimeEvent>

  formatDate(date: string) {
    return moment(date, 'YYYY-MM-D').locale('en-us').format('D MMMM YYYY')
  }

  showEvent(item: TimeEvent) {
    this.$emit('show:itemevent', item)
  }
}
